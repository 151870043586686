import { Page, Content } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  TemplateBackstageLogo,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageRandomJoke,
  TemplateBackstageLogoIcon,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { InfoCard } from '@backstage/core-components';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: 'https://backstage.io/docs',
    label: 'Backstage Docs',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://sgts.gitlab-dedicated.com/innersource/tech-practice/swe/projects/backstage/backstage',
    label: 'Backstage on SHIP',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://backstage.io/plugins',
    label: 'Plugins Directory',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://sgts.gitlab-dedicated.com/innersource/tech-practice/swe/projects/backstage/backstage/-/issues/new',
    label: 'Submit New Issue',
    icon: <TemplateBackstageLogoIcon />,
  },
];

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={2}>
            <HomePageCompanyLogo
              className={container}
              logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <InfoCard title="What is Backstage?">
                  <Typography>
                    Backstage is an platform designed to streamline development
                    workflows. Key features include:
                    <ul>
                      <li>
                        <strong>Centralised Catalog:</strong> Register and
                        discover all services, resources, and internal APIs in
                        one organised repository.
                      </li>
                      <li>
                        <strong>Transparency:</strong> Ensures clear and
                        accessible project documentation across teams.
                      </li>
                      <li>
                        <strong>Templates:</strong> Quickly start new projects
                        with customisable, best-practice templates.
                      </li>
                    </ul>
                  </Typography>
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <HomePageRecentlyVisited /> */}
                <HomePageRandomJoke />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={7}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={5}>
                <HomePageToolkit tools={tools} />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
